import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from 'recharts'

const COLORS = [
  '#a6cee3',
  '#1f78b4',
  '#b2df8a',
  '#33a02c',
  '#fb9a99',
  '#e31a1c',
  '#fdbf6f',
  '#ff7f00',
  '#cab2d6',
  '#6a3d9a',
  '#b15928',
  '#8dd3c7',
  '#ffffb3',
  '#bebada',
  '#fb8072',
  '#80b1d3',
  '#fdb462',
  '#b3de69',
  '#fccde5',
  '#d9d9d9',
  '#bc80bd',
  '#ccebc5',
  '#ffed6f',
  '#b3e2cd',
  '#fdcdac',
  '#cbd5e8',
  '#f4cae4',
  '#e6f5c9',
  '#fff2ae',
  '#f1e2cc',
  '#cccccc',
  '#66c2a5',
  '#fc8d62',
  '#8da0cb',
  '#e78ac3',
  '#a6d854',
  '#ffd92f',
  '#e5c494',
  '#b3b3b3',
  '#7fc97f',
  '#beaed4',
  '#fdc086',
  '#386cb0',
  '#f0027f',
  '#bf5b17',
  '#1b9e77',
  '#d95f02',
  '#7570b3',
  '#e7298a',
  '#66a61e',
  '#e6ab02',
  '#a6761d',
  '#666666',
]

const Overview = ({selectedRestaurants, combinedValues, selectedYear}) => {
  const { t } = useTranslation()
  const [selectedTimespan, setSelectedTimespan] = useState('year')
  const [chartOptions, setChartOptions] = useState({
    variable: 'waste',
    type: 'line',
    unit: 'percentage'
  })
  const [chartMax, setChartMax] = useState(100)
  const [chartAutoMax, setChartAutoMax] = useState(true)
  const [loading, setLoading] = useState(false)
  const [tableOptions, setTableOptions] = useState({
    sort: 'moneyWaste',
    type: 'percentage',
    perCustomer: false,
    hideUnits: false
  })
  const [init, setInit] = useState(false)
  const {
    weekServingStats,
    wasteTypes,
    orgs
  } = useSelector(state => state)
  const wasteTargetPercentage = orgs && orgs[0].wasteTargetPercentage ? orgs[0].wasteTargetPercentage : 0
  const wasteAlarmPercentage = orgs && orgs[0].wasteAlarmPercentage ? orgs[0].wasteAlarmPercentage : 100

  if (!init) {
    setInit(true)
  }

  const wasteTypeButtons = () => {
    let html = []
    let wasteTypeArr = wasteTypes.slice().sort((a, b) => {
      if (a.name === 'line') return -1
      if (b.name === 'line') return 1
      if (a.name === 'plate') return -1
      if (b.name === 'plate') return 1
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
      return 0
    })

    wasteTypeArr.forEach(function (type) {
      let name = type.name
      if (name === 'line') {
        name = 'Linjastohävikki'
      } else if (name === 'plate') {
        name = 'Lautashävikki'
      }
      html.push(<button
        type="button"
        className={'button is-small' + (chartOptions.type === type.name ? ' is-primary-background' : '')}
        style={{margin: '5px'}}
        onClick={() => {
          setChartOptions({
            ...chartOptions,
            type: type.name
          })
        }}
      >
        {t(name)}
      </button>)
    })

    return html
  }

  const overview = () => {
    if (weekServingStats) {
      let data = []
      let linesHtml = []
      let tableData = {}
      let tableHtml = []
      let restaurantNames = []
      if (combinedValues) {
        linesHtml.push(<Line dataKey={t('Kaikki valitut ravintolat')} />)
      }

      let columns = []
      let columnNames = []
      let columnUnits = []

      if (tableOptions.type === 'waste') {
        columns = [
          'restaurant',
          'customers',
          'moneyWaste',
          'prepared'
        ]
        columnNames = [
          'Ravintola',
          'Asiakasmäärä',
          'Hukattu raha',
          'Tarjoilumäärä'
        ]
        columnUnits = [
          '',
          '',
          '€',
          'kg',
          'kg'
        ]
      } else if (tableOptions.type === 'percentage') {
        columns = [
          'restaurant',
          'customers',
          'moneyWaste',
          'prepared'
        ]
        columnNames = [
          'Ravintola',
          'Asiakasmäärä',
          'Hukattu raha',
          'Tarjoilumäärä'
        ],
        columnUnits = [
          '',
          '',
          '€',
          'kg'
        ]
      } else if (tableOptions.type === 'co2') {
        columns = [
          'restaurant',
          'customers',
          'moneyWaste'
        ]
        columnNames = [
          'Ravintola',
          'Asiakasmäärä',
          'Hukattu raha'
        ]
        columnUnits = [
          '',
          '',
          '€'
        ]
      }
      
      let wasteTypeArr = wasteTypes.slice().sort((a, b) => {
        if (a.name === 'line') return -1
        if (b.name === 'line') return 1
        if (a.name === 'plate') return -1
        if (b.name === 'plate') return 1
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
        return 0
      })
      wasteTypeArr.forEach(function (type) {
        columns.push(type.name)

        if (tableOptions.type === 'percentage') {
          columnUnits.push('%')
        } else {
          columnUnits.push('kg')
        }

        if (type.name === 'line') {
          columnNames.push('Linjastohävikki')
        } else if (type.name === 'plate') {
          columnNames.push('Lautashävikki')
        } else {
          columnNames.push(type.name)
        }
      })

      let minWeek = 1
      let maxWeek = 53

      if (selectedTimespan === 'q1') {
        maxWeek = 13
      } else if (selectedTimespan === 'q2') {
        minWeek = 14
        maxWeek = 26
      } else if (selectedTimespan === 'q3') {
        minWeek = 27
        maxWeek = 39
      } else if (selectedTimespan === 'q4') {
        minWeek = 40
        maxWeek = 53
      }
      if (weekServingStats[selectedYear]) {
        Object.keys(weekServingStats[selectedYear]).filter(week => week >= minWeek && week <= maxWeek).forEach(function (week) {
          let obj = {
            week: week
          }
          let totals = {
            prepared: 0,
            customers: 0,
            moneyWaste: 0,
            wasteTypes: []
          }
          wasteTypes.forEach(function (type) {
            totals.wasteTypes.push({
              name: type.name,
              waste: 0,
              co2: 0
            })
          })

          weekServingStats[selectedYear][week].forEach(function (stats) {
            if (restaurantNames.indexOf(stats.restaurantName) === -1) {
              if (combinedValues === false) {
                linesHtml.push(<Line key={stats.restaurantName} dataKey={stats.restaurantName} stroke={COLORS[restaurantNames.length]} />)
              }

              restaurantNames.push(stats.restaurantName)
              tableData[stats.restaurantName] = {
                restaurant: stats.restaurantName,
                prepared: 0,
                customers: 0,
                moneyWaste: 0,
                consumed: 0
              }
              wasteTypes.forEach(function (type) {
                tableData[stats.restaurantName][type.name] = 0
                tableData[stats.restaurantName][type.name]  = 0
              })
            }

            totals.prepared = totals.prepared + parseInt(stats.preparedAmountGrams)
            totals.customers = totals.customers + parseInt(stats.totalCustomers)
            totals.moneyWaste = totals.moneyWaste + parseFloat(stats.moneyWaste)
            totals.wasteTypes.forEach(function (type) {
              type.waste = type.waste + parseInt(stats[(type.name.replace(' ', '-')).toLowerCase().replace(/-([a-z])/g, function (g) { return g[1].toUpperCase()}) + 'WasteGrams'])
              type.co2 = type.co2 + parseInt(stats[(type.name.replace(' ', '-')).toLowerCase().replace(/-([a-z])/g, function (g) { return g[1].toUpperCase()}) + 'Co2'])
            })

            tableData[stats.restaurantName].prepared = parseInt(tableData[stats.restaurantName].prepared) + parseInt(stats.preparedAmountGrams)
            tableData[stats.restaurantName].moneyWaste = parseFloat(tableData[stats.restaurantName].moneyWaste) + parseFloat(stats.moneyWaste)
            tableData[stats.restaurantName].customers = parseInt(tableData[stats.restaurantName].customers) + parseInt(stats.totalCustomers)
            tableData[stats.restaurantName].consumed = parseInt(tableData[stats.restaurantName].consumed) + parseInt(stats.preparedAmountGrams - stats.lineWasteGrams)
            totals.wasteTypes.forEach(function (type) {
              if (tableOptions.type === 'co2') {
                tableData[stats.restaurantName][type.name] = tableData[stats.restaurantName][type.name] + parseInt(stats[(type.name.replace(' ', '-')).toLowerCase().replace(/-([a-z])/g, function (g) { return g[1].toUpperCase()}) + 'Co2'])
              } else {
                tableData[stats.restaurantName][type.name] = tableData[stats.restaurantName][type.name] + parseInt(stats[(type.name.replace(' ', '-')).toLowerCase().replace(/-([a-z])/g, function (g) { return g[1].toUpperCase()}) + 'WasteGrams'])
              }
            })

            if (combinedValues === false) {
              if (chartOptions.variable === 'money') {
                obj[stats.restaurantName] = stats.moneyWaste > 0 ? parseFloat((stats.moneyWaste).toFixed(2)) : 0
              } else if (chartOptions.variable === 'consume') {
                obj[stats.restaurantName] = stats.preparedAmountGrams - stats.lineWasteGrams
              } else if (chartOptions.variable === 'waste') {
                obj[stats.restaurantName] = stats[(chartOptions.type.replace(' ', '-')).toLowerCase().replace(/-([a-z])/g, function (g) { return g[1].toUpperCase()}) + 'WasteGrams'] ? stats[(chartOptions.type.replace(' ', '-')).toLowerCase().replace(/-([a-z])/g, function (g) { return g[1].toUpperCase()}) + 'WasteGrams'] : 0
              } else if (chartOptions.variable === 'co2') {
                obj[stats.restaurantName] = stats[(chartOptions.type.replace(' ', '-')).toLowerCase().replace(/-([a-z])/g, function (g) { return g[1].toUpperCase()}) + 'Co2'] ? stats[(chartOptions.type.replace(' ', '-')).toLowerCase().replace(/-([a-z])/g, function (g) { return g[1].toUpperCase()}) + 'Co2'] : 0
              }
  
              if (chartOptions.variable !== 'money' && chartOptions.unit === 'percentage') {
                if (stats.preparedAmountGrams > 0) {
                  obj[stats.restaurantName] = parseFloat((obj[stats.restaurantName] / stats.preparedAmountGrams * 100).toFixed(1))
                } else {
                  obj[stats.restaurantName] = 0
                }
              } else if (chartOptions.variable !== 'money' && chartOptions.unit === 'perCustomer') {
                if (stats.totalCustomers > 0) {
                  obj[stats.restaurantName] = parseFloat((obj[stats.restaurantName] / stats.totalCustomers / 1000).toFixed(3))
                } else {
                  obj[stats.restaurantName] = 0
                }
              } else if (chartOptions.variable !== 'money') {
                obj[stats.restaurantName] = parseFloat((obj[stats.restaurantName] / 1000).toFixed(1))
              }
            }
          })

          if (combinedValues === true) {
            if (chartOptions.variable === 'money') {
              obj[t('Kaikki valitut ravintolat')] = totals.moneyWaste > 0 ? parseFloat((totals.moneyWaste).toFixed(2)) : 0
            } else if (chartOptions.variable === 'consume') {
              obj[t('Kaikki valitut ravintolat')] = totals.prepared - totals.wasteTypes.find(wtd => wtd.name === 'line').waste
            } else if (chartOptions.variable === 'waste') {
              obj[t('Kaikki valitut ravintolat')] = totals.wasteTypes.find(wtd => wtd.name === chartOptions.type).waste
            } else if (chartOptions.variable === 'co2') {
              obj[t('Kaikki valitut ravintolat')] = totals.wasteTypes.find(wtd => wtd.name === chartOptions.type).co2
            }

            if (chartOptions.variable !== 'money' && chartOptions.unit === 'percentage') {
              if (totals.prepared > 0) {
                obj[t('Kaikki valitut ravintolat')] = parseFloat((obj[t('Kaikki valitut ravintolat')] / totals.prepared * 100).toFixed(1))
              } else {
                obj[t('Kaikki valitut ravintolat')] = 0
              }
            } else if (chartOptions.variable !== 'money' && chartOptions.unit === 'perCustomer') {
              if (totals.customers > 0) {
                obj[t('Kaikki valitut ravintolat')] = parseFloat((obj[t('Kaikki valitut ravintolat')] / totals.customers / 1000).toFixed(3))
              } else {
                obj[t('Kaikki valitut ravintolat')] = 0
              }
            } else if (chartOptions.variable !== 'money') {
              obj[t('Kaikki valitut ravintolat')] = parseFloat((obj[t('Kaikki valitut ravintolat')] / 1000).toFixed(1))
            }
          }
          data.push(obj)
        })
      }

      let tableDataArr =  []
      let tableTotalValues = {}
      let tableAvgValues = {}
      columns.forEach(function (column) {
        if (column !== 'restaurant') {
          tableTotalValues[column] = 0
          tableAvgValues[column] = 0
        }
      })

      Object.keys(tableData).forEach(function (key) {
        let arrData = {}
        columns.forEach(function (column, index) {
          arrData[column] = tableData[key][column]
          if (column !== 'restaurant') {
            tableTotalValues[column] = tableTotalValues[column] + tableData[key][column]
          }
          if (columnUnits[index] === '%') {
            if (column === 'consume') {
              arrData[column] = (tableData[key]['prepared'] - tableData[key]['lineWasteGrams']) / tableData[key]['prepared'] * 100
            } else {
              arrData[column] = tableData[key][column] / tableData[key]['prepared'] * 100
            }
          }
        })
        tableDataArr.push(arrData)
      })

      tableDataArr.sort(function (a, b) {
        let first = a[tableOptions.sort]
        let second = b[tableOptions.sort]
        if (typeof a[columns[tableOptions.sort]] === 'string') {
          first = first.toLowerCase()
          second = second.toLowerCase()
          if (first < second) return -1
          if (first > second) return 1
        } else {
          if (first < second) return 1
          if (first > second) return -1
        }
        return 0
      })

      let tableColumnsHtml = []
      let restaurantCount = Object.keys(tableData).length

      columns.forEach(function (column, index) {
        if (column !== 'restaurant') {
          tableAvgValues[column] = tableTotalValues[column] / restaurantCount
        }
        tableColumnsHtml.push(<td
          style={tableOptions.sort === column ? {background: '#000', color: '#fff'} : {}}
          onClick={() => {
            setTableOptions({
              ...tableOptions,
              sort: column
            })
          }}
        >
          <b>{t(columnNames[index])}</b>
        </td>)
      })

      tableHtml.push(<tr key="columnHeaders" style={{cursor: 'pointer'}}>{tableColumnsHtml}</tr>)

      tableDataArr.forEach(function (data) {
        let tableCellsHtml = []
        columns.forEach(function (column, index) {
          let className = ''
          let text = data[column]
          if (columnUnits[index] === 'kg') {
            text = parseFloat((data[column] / 1000).toFixed(1))
          } else if (columnUnits[index] === '€') {
            text = (data[column]).toFixed(2)
          } else if (columnUnits[index] === '%') {
            text = parseFloat((data[column]).toFixed(1))
            if (data[column] < wasteTargetPercentage) {
              className = 'targetValue'
            } else if (data[column] > wasteAlarmPercentage) {
              className = 'alarmValue'
            }
          }
          text = text + columnUnits[index]
          tableCellsHtml.push(<td className={className}>{text}</td>)
        })
        tableHtml.push(<tr key={'data' + data['restaurant']}>{tableCellsHtml}</tr>)
      })

      let tableTotalHtml = []
      let tableAvgHtml = []

      columns.forEach(function (column, index) {
        if (column !== 'restaurant') {
          let totalsClass = ''
          let avgsClass = ''
          let totalsValue = tableTotalValues[column]
          let avgsValue = tableAvgValues[column]
          if (columnUnits[index] === 'kg') {
            totalsValue = parseFloat((tableTotalValues[column] / 1000).toFixed(1))
            avgsValue = parseFloat((tableAvgValues[column] / 1000).toFixed(1))
          } else if (columnUnits[index] === '€') {
            totalsValue = (tableTotalValues[column]).toFixed(2)
            avgsValue = (tableAvgValues[column]).toFixed(2)
          } else if (columnUnits[index] === '%') {
            if (column === 'menekki') {
              totalsValue = parseFloat(((tableTotalValues['prepared'] - tableTotalValues['lineWasteGrams']) / tableTotalValues['prepared'] * 100).toFixed(1))
              avgsValue = parseFloat(((tableAvgValues['prepared'] - tableAvgValues['lineWasteGrams']) / tableAvgValues['prepared'] * 100).toFixed(1))
            } else {
              totalsValue = parseFloat((tableTotalValues[column] / tableTotalValues['prepared'] * 100).toFixed(1))
              avgsValue = parseFloat((tableAvgValues[column] / tableAvgValues['prepared'] * 100).toFixed(1))

              if (totalsValue < wasteTargetPercentage) {
                totalsClass = 'targetValue'
              } else if (totalsValue > wasteAlarmPercentage) {
                totalsClass = 'alarmValue'
              }
              
              if (avgsValue < wasteTargetPercentage) {
                avgsClass = 'targetValue'
              } else if (avgsValue > wasteAlarmPercentage) {
                avgsClass = 'alarmValue'
              }
            }
          } else {
            totalsValue = parseFloat((tableTotalValues[column]).toFixed(1))
            avgsValue = parseFloat((tableAvgValues[column]).toFixed(1))
          }
          totalsValue = totalsValue + columnUnits[index]
          avgsValue = avgsValue + columnUnits[index]
          tableTotalHtml.push(<td className={totalsClass}><b>{totalsValue}</b></td>)
          tableAvgHtml.push(<td className={avgsClass}><b>{avgsValue}</b></td>)
        } else {
          tableTotalHtml.push(<td><b>{t('Yhteensä')}</b></td>)
          tableAvgHtml.push(<td><b>{t('Keskiarvo')}</b></td>)
        }
      })

      tableHtml.push(<tr style={{borderTop: '2px solid #000'}}>{tableTotalHtml}</tr>)
      tableHtml.push(<tr style={{borderTop: '2px solid #000'}}>{tableAvgHtml}</tr>)

      let unit = ''
      let domainMax = 'auto'
      
      if (chartAutoMax === false && chartMax !== null && chartMax !== 0) {
        domainMax = parseInt(chartMax)
      }

      if (chartOptions.variable === 'money') {
        unit = '€'
      } else if (chartOptions.unit === 'total' || chartOptions.unit === 'perCustomer') {
        unit = 'kg'
      } else if (chartOptions.unit === 'percentage') {
        unit = '%'
        if (domainMax === 'auto') {
          domainMax = 100
        }
      }

      return (
        <div>
          <ResponsiveContainer width="99%" height={500} minHeight={500}>
            <LineChart
              data={data}
              margin={{
                top: 40,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              {linesHtml}
              <XAxis dataKey="week" />
              <YAxis tickFormatter={label => (label+unit)} domain={[0, domainMax]} />
              <Tooltip labelFormatter={title => (t('Viikko') + ' ' + title)} formatter={label => (label+unit)} />
              <Legend wrapperStyle={{ top: 500, left: 25 }} />
            </LineChart>
          </ResponsiveContainer>
          <div style={{background: '#fff', position: 'relative'}}>
            <div style={{marginTop: '120px'}}>
              <h2>{t('Chartin ylärajan säätö')}</h2>
              <button
                type="button"
                className={'button is-small' + (chartAutoMax === true ? ' is-secondary-background' : '')}
                style={{margin: '5px', color: (chartAutoMax === true ? '#fff' : '#000')}}
                onClick={() => {
                  setChartAutoMax(true)
                }}
              >
                <Trans>Auto</Trans>
              </button>
              <button
                type="button"
                className={'button is-small' + (chartAutoMax === false ? ' is-secondary-background' : '')}
                style={{margin: '5px', color: (chartAutoMax === false ? '#fff' : '#000')}}
                onClick={() => {
                  setChartAutoMax(false)
                }}
              >
                <Trans>Manuaalinen</Trans>
              </button>
              <br />
              <input
                type="number"
                value={chartMax}
                onChange={(e) => {
                  setChartMax(e.target.value)
                }}
              /> 
            </div>
            <div style={{margin: '50px auto'}}>
              <h2>{t('Taulukon asetukset')}</h2>
              <button
                type="button"
                className={'button is-small' + (tableOptions.type === 'percentage' ? ' is-primary-background' : '')}
                style={{margin: '5px'}}
                onClick={() => {
                  setTableOptions({
                    ...tableOptions,
                    type: 'percentage'
                  })
                }}
              >
                <Trans>Hävikki (%)</Trans>
              </button>
              <button
                type="button"
                className={'button is-small' + (tableOptions.type === 'waste' ? ' is-primary-background' : '')}
                style={{margin: '5px'}}
                onClick={() => {
                  setTableOptions({
                    ...tableOptions,
                    type: 'waste'
                  })
                }}
              >
                <Trans>Hävikki (kg)</Trans>
              </button>
              <button
                type="button"
                className={'button is-small' + (tableOptions.type === 'co2' ? ' is-primary-background' : '')}
                style={{margin: '5px'}}
                onClick={() => {
                  let sort = tableOptions.sort
                  if (sort === 'prepared' || sort === 'consumed') {
                    sort = 'moneyWaste'
                  }
                  setTableOptions({
                    ...tableOptions,
                    type: 'co2',
                    sort
                  })
                }}
              >
                <Trans>Co2 (kg)</Trans>
              </button>
              <table className="table is-striped is-bordered" style={{margin: '5px auto'}}>
                <tbody>
                  {tableHtml}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )
    }
  }

  return (<div>
    <div>  
      <div>
        <button
          type="button"
          className={'button is-small' + (selectedTimespan === 'year' ? ' is-primary-background' : '')}
          style={{margin: '5px'}}
          onClick={() => {
            setSelectedTimespan('year')
          }}
        >
          <Trans>Vuosi</Trans>
        </button>
        <button
          type="button"
          className={'button is-small' + (selectedTimespan === 'q1' ? ' is-primary-background' : '')}
          style={{margin: '5px'}}
          onClick={() => {
            setSelectedTimespan('q1')
          }}
        >
          <Trans>Q1</Trans>
        </button>
        <button
          type="button"
          className={'button is-small' + (selectedTimespan === 'q2' ? ' is-primary-background' : '')}
          style={{margin: '5px'}}
          onClick={() => {
            setSelectedTimespan('q2')
          }}
        >
          <Trans>Q2</Trans>
        </button>
        <button
          type="button"
          className={'button is-small' + (selectedTimespan === 'q3' ? ' is-primary-background' : '')}
          style={{margin: '5px'}}
          onClick={() => {
            setSelectedTimespan('q3')
          }}
        >
          <Trans>Q3</Trans>
        </button>
        <button
          type="button"
          className={'button is-small' + (selectedTimespan === 'q4' ? ' is-primary-background' : '')}
          style={{margin: '5px'}}
          onClick={() => {
            setSelectedTimespan('q4')
          }}
        >
          <Trans>Q4</Trans>
        </button>
      </div>
      <br />
      <div>
        <button
          type="button"
          className={'button is-small' + (chartOptions.variable === 'waste' ? ' is-primary-background' : '')}
          style={{margin: '5px'}}
          onClick={() => {
            setChartOptions({
              ...chartOptions,
              variable: 'waste'
            })
          }}
        >
          <Trans>Hävikki</Trans>
        </button>
        <button
          type="button"
          className={'button is-small' + (chartOptions.variable === 'money' ? ' is-primary-background' : '')}
          style={{margin: '5px'}}
          onClick={() => {
            setChartOptions({
              ...chartOptions,
              variable: 'money'
            })
          }}
        >
          <Trans>Hukattu raha</Trans>
        </button>
        <button
          type="button"
          className={'button is-small' + (chartOptions.variable === 'co2' ? ' is-primary-background' : '')}
          style={{margin: '5px'}}
          onClick={() => {
            let unit = chartOptions.unit
            if (unit === 'percentage') {
              unit = 'total'
            }
            setChartOptions({
              ...chartOptions,
              variable: 'co2',
              unit
            })
          }}
        >
          <Trans>CO2</Trans>
        </button>
      </div>
      { (chartOptions.variable === 'waste' || chartOptions.variable === 'co2') && (<>
        <div>
          {wasteTypeButtons()}
        </div>
      </>)}
      {chartOptions.variable !== 'money' && (<>
        <div>
          { chartOptions.variable !== 'co2' && (
            <button
              type="button"
              className={'button is-small' + (chartOptions.unit === 'percentage' ? ' is-primary-background' : '')}
              style={{margin: '5px'}}
              onClick={() => {
                setChartOptions({
                  ...chartOptions,
                  unit: 'percentage'
                })
              }}
            >
              <Trans>%</Trans>
            </button>
          )}
          <button
            type="button"
            className={'button is-small' + (chartOptions.unit === 'perCustomer' ? ' is-primary-background' : '')}
            style={{margin: '5px'}}
            onClick={() => {
              setChartOptions({
                ...chartOptions,
                unit: 'perCustomer'
              })
            }}
          >
            <Trans>Per asiakas</Trans>
          </button>
          <button
            type="button"
            className={'button is-small' + (chartOptions.unit === 'total' ? ' is-primary-background' : '')}
            style={{margin: '5px'}}
            onClick={() => {
              setChartOptions({
                ...chartOptions,
                unit: 'total'
              })
            }}
          >
            <Trans>Kg</Trans>
          </button>
        </div>
      </>)}
    </div>
    {selectedRestaurants && selectedRestaurants.length > 0 && overview()}
  </div>)
}

export default Overview